import axios from 'axios';
import { fetchImage } from '../utils/fecthImage';
import { IRehab, PatientData, RehabPatientAnswers, UserAuth } from '../interface';
import { PostPatienteInteractionByTextBody, PostPatienteInteractionByVideoBody } from '../types/patienteInteraction';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

api.interceptors.request.use(
    (config) => {
        // if (!config.url?.endsWith('auth')) {
        config.withCredentials = true;
        config.maxBodyLength = 100000;
        config.maxContentLength = 100000;
        // }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response: any) => {
        response.withCredentials = true;
        response.maxBodyLength = 100000;
        response.maxContentLength = 100000;

        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            const requestConfig = error.config;

            localStorage.removeItem('@App:paciente');
            localStorage.setItem('@disconnected', 'true');

            window.location.href = '/';

            return axios(requestConfig);
        }
        return Promise.reject(error);
    }
);

export const getAuth = async (email: string, password: string): Promise<UserAuth> => {
    const { data } = await api.get(`loginpatient?email=${email}&password=${encodeURIComponent(password)}`);

    const userAuth: UserAuth = {
        uuid: data.uuid,
        nome: data.nome,
        email: data.email,
    };

    return new Promise<UserAuth>((resolve, reject) => {
        if (userAuth) {
            resolve(userAuth);
        } else {
            const error = new Error('Houve um erro!!!!');
            console.log(error);
            reject(error);
        }
    });
};

export const sendEmailRecovery = async (email: string) => {
    const response = await api.post(`passwordrecovery/send-mail`, { email });
    return response;
};

export const checkTokenValidity = async (token: string) => {
    try {
      const {data: { body: response }} = await api.post(`passwordrecovery/verify-token`, { token })
      return response
    } catch (error) {
      return { expired: true }
    }
  }

export const getUserData = async (patientId: string): Promise<PatientData> => {
    let paciente: PatientData;
    try {
        const { data } = await api.get(`paciente/searchpaciente?uuid=${patientId}`);
        const user: PatientData = data.paciente[0];
        const avatar = (await fetchImage('pacientes', user.foto)) || '';
        paciente = { ...user, foto: avatar };
    } catch (error: any) {
        console.error(error);
    }

    return new Promise<PatientData>((resolve, reject) => {
        if (paciente) {
            resolve(paciente);
        } else {
            const error = new Error('Houve um erro!!!!');
            reject(error);
        }
    });
};

export const updateUserData = async (patientId: string, values: FormData) => {
    api.put(`paciente/update?uuid=${patientId}`, values, {
        headers: {
            'content-type': 'multi-part/form-data',
        },
    });
};

export const getRehab = async (patientId: string): Promise<IRehab> => {
    try {
        const { data } = await api.get(`reabilitacaopaciente/searchpaciente?search=${patientId}`);
        return data.reabilitacao_paciente[0];
    } catch (error) {
        throw error;
    }
};

export const createPatientInteractionRecord = async (
    data: PostPatienteInteractionByVideoBody | PostPatienteInteractionByTextBody
) => {
    try {
        if ('perguntaMedicoId' in data) {
            await api.post('interacaopaciente/video', data);
        } else {
            await api.post('interacaopaciente/texto', data);
        }
    } catch (error) {
        throw error;
    }
};

export const getRehabPatientAnswers = async (patientId: string): Promise<RehabPatientAnswers[]> => {
    try {
        const { data } = await api.get(`repostaspaciente/search?patientId=${patientId}`);
        return data;
    } catch (error) {
        throw error;
    }
};

export default api;
